div.LoaderContainer{
  justify-self: right;
  flex-flow: column;
  flex: 1;
  margin: 0;
  border-radius: var(--brdRadius);
  background-color: var(--mainWhite);
  padding: 15px;
  min-height: 140px;
}

/* @media screen and (min-width: 601px) { */
  div.Container{
    justify-self: right;
    flex-flow: column nowrap;
    /* max-width: 60vw; */
    max-height: 77vh;
    margin-bottom: 50px;
    flex: 4 4;
    overflow-x: auto;
    /* white-space: nowrap; */
    margin: 5px 5px 0 0;
    border-radius: var(--brdRadius);

    div.TopDiv {
      text-align: center;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;

      div.Oops{
        margin-top: 20vh;
        color: #FFF;

        div.title{
          text-align: center;
          font-size: 3.5vw;
        }
        div.subtitle{
          text-align: center;
          font-size: 1.7vw;
        }
      }
    }
  }

@media (max-width: 600px) {
  div.Container{
    /* border: 1px solid red; */
    padding: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
    
    div.TopDiv{
      /* border: 1px solid blue; */
      margin: auto;
      margin-top: 15px;
      margin-bottom: 65px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      /* justify-content: space-between; */
      justify-content: flex-start;

      width: 84%;

    }
  }

  div.LoaderContainer{
    margin: 20px;
  }
}