div.Container{
    /* border: 1px solid blue; */
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
    position: relative;

    div.UserChoosing{
        /* border: 1px solid blue; */
        height: 100%;

        div.OptionBtns{
            /* border: 3px solid green; */
            display: flex;
            justify-content: center;
    
            button{
                width: 100%;
                height: 30px;
                outline: none;
                color: #FFFF;
                border: none;
                border-radius: 3px;
                /* margin: 0 7px 0 7px; */
                cursor: pointer;
                font-family: 'Heebo', sans-serif;
                font-size: 15px;
            }

            button:hover{
                opacity: .9;
            }
        }

        div.OptionBtns > * + *{
            margin-right: 3%;
        }
        div.InputFields{
            height: 90%
        }
        
        div.InputFields form{
            /* border: 13px solid purple; */
            margin-top: 10px;
            height: 100%;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            div.Inputs{
                /* border: 3px solid blue; */
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                input{
                    display: inline-block;
                    width: 100%;
                    height: 30px;
                    border:none;
                    outline: none;
                    border-radius: 3px;
                    resize: none;
                    overflow: auto;
                    font-family: 'Heebo', sans-serif;
                    padding: 2px 5px 2px 5px;
                }
    
                input::placeholder{ /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #adabab;
                    opacity: 1; /* Firefox */
                }
                  
                input:-ms-input-placeholder{ /* Internet Explorer 10-11 */
                    color: #adabab;
                }
                  
                input::-ms-input-placeholder{ /* Microsoft Edge */
                    color: #adabab;
                }
    
                div.StreetField{
                    /* border: 1px solid red; */
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
    
                    div.LeftElement{
                        position: absolute;
                        left: 10px;
                        
                        label{
                            font-size: 12px;
                            color: #adabab;
                            cursor: pointer;
                        }
    
                        label:nth-child(1){
                            font-weight: 550;
                            text-decoration: underline;
                            color: #000;
                        }
                    }
                    
                    input{
                        display: inline-block;
                        height: 30px;
                        border:none;
                        outline: none;
                        border-radius: 3px;
                        resize: none;
                        overflow: auto;
                        font-family: 'Heebo', sans-serif;
                        padding: 2px 5px 2px 5px;
                    }
        
                    input::placeholder{ /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: #adabab;
                        opacity: 1; /* Firefox */
                    }
                      
                    input:-ms-input-placeholder{ /* Internet Explorer 10-11 */
                        color: #adabab;
                    }
                      
                    input::-ms-input-placeholder{ /* Microsoft Edge */
                        color: #adabab;
                    }
                }
    
                div.TowInputInRow{
                    display: flex;
                    width: 100%;
    
                    input{
                        height: 30px;
                    }
                    
                    input:nth-child(1){
                        margin-left: 6px;
                    }
                }
                
                div.CheckTerm{
                    /* border: 1px solid red; */
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    overflow: hidden;
                    input{
                        width: 15px;
                    }
                    span{
                        /* border: 1px solid red; */
                        width: 20rem;
                        font-size: 17px;
                        /* font-weight: 600; */
                        color: #FFF;
                    }
    
                    .TextTermBtn{
                        font-weight: 900;
                        text-decoration: underline;
                        color: #fff;
                    }
                    
                    .TextTermBtn:hover{
                        cursor: pointer;
                    }
                }
            }

            div.Inputs > *{
                margin-bottom: 5px;
            }

            div.BottomBtns{
                /* border: 1px solid red; */
                display: flex;
                
                flex-direction: column;

                button{
                    font-family: 'Heebo', sans-serif;
                    height: 50px;
                    font-size: 27px;
                    cursor: pointer;
                }

                button:nth-child(1){
                    background-color: #FF0000;
                    width: 350px;
                    border-radius: 30px;
                    outline: none;
                    border:none;
                    color: #FFFF;
                }
                button:nth-child(2){
                    margin-top: 15px;
                    background-color: unset;
                    width: 350px;
                    border-radius: 30px;
                    outline: none;
                    border: 2px solid rgb(255, 255, 255, 0.3);
                    color: #FFFF;
                }

                button:hover{
                    opacity: .9;
                }
            }
        }

        /* div.InputFields form > *{
            margin-bottom: 5px;
        } */
    }
}

@media (max-width: 850px){
    div.Container {
        width: 300px;
        padding-top: 10px;
        div.UserChoosing{
            div.InputFields form{
                display: flex;
                flex-direction: column;
                /* gap: 5px; */
                margin-top: 10px;
                align-items: center;

                input{
                }
    
                div.StreetField{
                    position: relative;
                    display: flex;
                    align-items: center;
    
                    div.LeftElement{
                        position: absolute;
                        left: 10px;
                        
                        label{
                            font-size: 12px;
                            color: #adabab;
                        }
                        label:hover{
                            cursor: pointer;
                        }

                        label:nth-child(1){
                            font-weight: 550;
                            text-decoration: underline;
                            color: #000000;
                        }
                    }

                    input{
                    }
                }
    
                div.TowInputInRow{
                    width: 250px;
                }

                div.CheckTerm{
                    width: 250px;
                    color: #FFF;
                    span{
                    }
                    input{
                        width: 10%;
                    }
                }

                div.BottomBtns{
                        /* gap: 10px;   */
                    button{
                        font-size: 19px;
                    }
    
                    button:nth-child(1){
                        width: 250px;
                        height: 30px;
                    }
                    button:nth-child(2){
                        margin-top: 10px;
                        width: 250px;
                        height: 30px;
                    }
                }
            }
        }
    }
}